var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"projects-section",attrs:{"id":"projects"}},[_c('h4',{staticClass:"projects-subtitle",attrs:{"data-aos":"fade-in","data-aos-duration":"500","data-aos-delay":"250","data-aos-once":"true"}},[_vm._v(" PORTFOLIO ")]),_c('h3',{staticClass:"projects-title",attrs:{"data-aos":"fade-int","data-aos-duration":"1000","data-aos-once":"true"}},[_vm._v(" Personal Projects ")]),_c('div',{staticClass:"collection"},[_c('a',{staticClass:"collection-item",attrs:{"data-aos":"slide-up","data-aos-duration":"1000","data-aos-once":"true"},on:{"click":_vm.showModal1}},[_vm._m(0),_c('img',{staticClass:"collection-image",attrs:{"src":require("../assets/proj1.jpg"),"alt":""}})]),_c('a',{staticClass:"collection-item",attrs:{"data-aos":"slide-up","data-aos-duration":"500","data-aos-once":"true"},on:{"click":_vm.showModal}},[_vm._m(1),_c('img',{staticClass:"collection-image",attrs:{"src":require("../assets/inkwell.jpg"),"alt":""}})]),_c('a',{staticClass:"collection-item",attrs:{"data-aos":"slide-up","data-aos-duration":"1000","data-aos-once":"true"},on:{"click":_vm.showModal1}},[_vm._m(2),_c('img',{staticClass:"collection-image",attrs:{"src":require("../assets/qxchange.jpg"),"alt":""}})]),_c('a',{staticClass:"collection-item",attrs:{"data-aos":"slide-up","data-aos-duration":"1500","data-aos-once":"true"},on:{"click":_vm.showModal}},[_vm._m(3),_c('img',{staticClass:"collection-image",attrs:{"src":require("../assets/kairos.jpg"),"alt":""}})]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collection-content"},[_c('h4',{staticClass:"collection-title"},[_vm._v("DragNDrop")]),_c('h5',{staticClass:"collection-subtitle"},[_vm._v(" A project management application to help organize your workflow and track tasks. Created using React, Express.js, Node.js, Sequelize and PostgreSQL ")]),_c('div',{staticClass:"view-button-wrapper"},[_c('a',{staticClass:"view-button",attrs:{"href":"https://dragndrop226.netlify.app/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" View Demo ")]),_c('a',{staticClass:"view-button",attrs:{"href":"https://github.com/Evany226/Drag-and-Drop-v2","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("View on Github")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collection-content"},[_c('h4',{staticClass:"collection-title"},[_vm._v("Inkwell")]),_c('h5',{staticClass:"collection-subtitle"},[_vm._v(" A note taking app made to be simple and efficient. Created using React, Typescript, Tailwind and Firebase ")]),_c('div',{staticClass:"view-button-wrapper"},[_c('a',{staticClass:"view-button",attrs:{"href":"https://inkwell1.vercel.app/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" View Demo ")]),_c('a',{staticClass:"view-button",attrs:{"href":"https://github.com/Evany226/Inkwell","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("View on Github ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collection-content"},[_c('h4',{staticClass:"collection-title"},[_vm._v("QXChange")]),_c('h5',{staticClass:"collection-subtitle"},[_vm._v(" A forums app created for a 24 hr hackathon to help students with homework. Created using the MERN Stack (MongoDB, Express.js, React, Node.js) ")]),_c('div',{staticClass:"view-button-wrapper"},[_c('a',{staticClass:"view-button",attrs:{"href":"https://hackru23-gb9a.vercel.app/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" View Demo ")]),_c('a',{staticClass:"view-button",attrs:{"href":"https://github.com/ZhangTerrence/qxchange","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("View on Github ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collection-content"},[_c('h4',{staticClass:"collection-title"},[_vm._v("Kairos")]),_c('h5',{staticClass:"collection-subtitle"},[_vm._v(" A job finder app created for a 24 hour hackathon that scrapes job board sites and recommends jobs that are suitable for you. Created using React, Python, BS4 and Selenium ")]),_c('div',{staticClass:"view-button-wrapper"},[_c('a',{staticClass:"view-button",attrs:{"href":"https://github.com/ZhangTerrence/qxchange","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("View on Github ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"collection-item",attrs:{"data-aos":"slide-up","data-aos-duration":"1000","data-aos-once":"true"}},[_c('div',{staticClass:"collection-content"},[_c('h4',{staticClass:"collection-title"},[_vm._v("Project 5")]),_c('h5',{staticClass:"collection-subtitle"},[_vm._v("Description of Project 5")])]),_c('img',{staticClass:"collection-image",attrs:{"src":require("../assets/portfolio-pic-5.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"collection-item",attrs:{"data-aos":"slide-up","data-aos-duration":"1500","data-aos-once":"true"}},[_c('div',{staticClass:"collection-content"},[_c('h4',{staticClass:"collection-title"},[_vm._v("Project 6")]),_c('h5',{staticClass:"collection-subtitle"},[_vm._v("Description of Project 6")])]),_c('img',{staticClass:"collection-image",attrs:{"src":require("../assets/portfolio-pic-6.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"collection-item",attrs:{"data-aos":"slide-up","data-aos-duration":"500","data-aos-once":"true"}},[_c('div',{staticClass:"collection-content"},[_c('h4',{staticClass:"collection-title"},[_vm._v("Project 7")]),_c('h5',{staticClass:"collection-subtitle"},[_vm._v("Description of Project 7")])]),_c('img',{staticClass:"collection-image",attrs:{"src":require("../assets/portfolio-pic-7.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"collection-item",attrs:{"data-aos":"slide-up","data-aos-duration":"1000","data-aos-once":"true"}},[_c('div',{staticClass:"collection-content"},[_c('h4',{staticClass:"collection-title"},[_vm._v("Project 8")]),_c('h5',{staticClass:"collection-subtitle"},[_vm._v("Description of Project 8")])]),_c('img',{staticClass:"collection-image",attrs:{"src":require("../assets/portfolio-pic-8.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"collection-item",attrs:{"data-aos":"slide-up","data-aos-duration":"1500","data-aos-once":"true"}},[_c('div',{staticClass:"collection-content"},[_c('h4',{staticClass:"collection-title"},[_vm._v("Project 9")]),_c('h5',{staticClass:"collection-subtitle"},[_vm._v("Description of Project 9")])]),_c('img',{staticClass:"collection-image",attrs:{"src":require("../assets/portfolio-pic-9.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }