<template>
  <section class="intro-section">
      <div class="first-container"> 
        <div class="svg-container">
         <svg width="449" height="97" viewBox="0 0 449 97" fill="none" xmlns="http://www.w3.org/2000/svg" id="amazing">
<mask id="path-1-outside-1_2_7" maskUnits="userSpaceOnUse" x="0.251953" y="0.90625" width="449" height="96" fill="black">
<rect fill="white" x="0.251953" y="0.90625" width="449" height="96"/>
<path d="M42.4375 41.1387H11.627V66.334H47.418V74H2.25195V2.90625H46.9297V10.6211H11.627V33.4727H42.4375V41.1387Z"/>
<path d="M73.834 61.7441L86.9199 21.168H96.1484L77.2031 74H70.3184L51.1777 21.168H60.4062L73.834 61.7441Z"/>
<path d="M136.676 74C136.155 72.9583 135.732 71.1029 135.406 68.4336C131.207 72.7956 126.194 74.9766 120.367 74.9766C115.159 74.9766 110.878 73.5117 107.525 70.582C104.205 67.6198 102.545 63.8763 102.545 59.3516C102.545 53.8503 104.628 49.5859 108.795 46.5586C112.994 43.4987 118.886 41.9688 126.471 41.9688H135.26V37.8184C135.26 34.6608 134.316 32.1543 132.428 30.2988C130.54 28.4108 127.757 27.4668 124.078 27.4668C120.855 27.4668 118.154 28.2806 115.973 29.9082C113.792 31.5358 112.701 33.5052 112.701 35.8164H103.619C103.619 33.1797 104.547 30.6406 106.402 28.1992C108.29 25.7253 110.829 23.7721 114.02 22.3398C117.242 20.9076 120.774 20.1914 124.615 20.1914C130.702 20.1914 135.471 21.7214 138.922 24.7812C142.372 27.8086 144.163 31.9915 144.293 37.3301V61.6465C144.293 66.4967 144.911 70.3542 146.148 73.2188V74H136.676ZM121.686 67.1152C124.518 67.1152 127.203 66.3828 129.742 64.918C132.281 63.4531 134.12 61.5488 135.26 59.2051V48.3652H128.18C117.112 48.3652 111.578 51.6042 111.578 58.082C111.578 60.9141 112.522 63.1276 114.41 64.7227C116.298 66.3177 118.723 67.1152 121.686 67.1152Z"/>
<path d="M166.998 21.168L167.291 27.8086C171.327 22.7305 176.601 20.1914 183.111 20.1914C194.277 20.1914 199.908 26.4902 200.006 39.0879V74H190.973V39.0391C190.94 35.2305 190.061 32.4147 188.336 30.5918C186.643 28.7689 183.99 27.8574 180.377 27.8574C177.447 27.8574 174.876 28.6387 172.662 30.2012C170.449 31.7637 168.723 33.8145 167.486 36.3535V74H158.453V21.168H166.998Z"/>
<path d="M261.529 38.5996L280.084 2.90625H290.729L266.217 47.4863V74H256.842V47.4863L232.33 2.90625H243.072L261.529 38.5996Z"/>
<path d="M327.496 74C326.975 72.9583 326.552 71.1029 326.227 68.4336C322.027 72.7956 317.014 74.9766 311.188 74.9766C305.979 74.9766 301.699 73.5117 298.346 70.582C295.025 67.6198 293.365 63.8763 293.365 59.3516C293.365 53.8503 295.449 49.5859 299.615 46.5586C303.814 43.4987 309.706 41.9688 317.291 41.9688H326.08V37.8184C326.08 34.6608 325.136 32.1543 323.248 30.2988C321.36 28.4108 318.577 27.4668 314.898 27.4668C311.676 27.4668 308.974 28.2806 306.793 29.9082C304.612 31.5358 303.521 33.5052 303.521 35.8164H294.439C294.439 33.1797 295.367 30.6406 297.223 28.1992C299.111 25.7253 301.65 23.7721 304.84 22.3398C308.062 20.9076 311.594 20.1914 315.436 20.1914C321.523 20.1914 326.292 21.7214 329.742 24.7812C333.193 27.8086 334.983 31.9915 335.113 37.3301V61.6465C335.113 66.4967 335.732 70.3542 336.969 73.2188V74H327.496ZM312.506 67.1152C315.338 67.1152 318.023 66.3828 320.562 64.918C323.102 63.4531 324.941 61.5488 326.08 59.2051V48.3652H319C307.932 48.3652 302.398 51.6042 302.398 58.082C302.398 60.9141 303.342 63.1276 305.23 64.7227C307.118 66.3177 309.544 67.1152 312.506 67.1152Z"/>
<path d="M357.818 21.168L358.111 27.8086C362.148 22.7305 367.421 20.1914 373.932 20.1914C385.097 20.1914 390.729 26.4902 390.826 39.0879V74H381.793V39.0391C381.76 35.2305 380.882 32.4147 379.156 30.5918C377.464 28.7689 374.811 27.8574 371.197 27.8574C368.268 27.8574 365.696 28.6387 363.482 30.2012C361.269 31.7637 359.544 33.8145 358.307 36.3535V74H349.273V21.168H357.818Z"/>
<path d="M402.301 47.1445C402.301 38.9089 404.205 32.3659 408.014 27.5156C411.822 22.6328 416.868 20.1914 423.15 20.1914C429.596 20.1914 434.625 22.4701 438.238 27.0273L438.678 21.168H446.93V72.7305C446.93 79.5664 444.895 84.9538 440.826 88.8926C436.79 92.8314 431.354 94.8008 424.518 94.8008C420.709 94.8008 416.982 93.987 413.336 92.3594C409.69 90.7318 406.907 88.502 404.986 85.6699L409.674 80.25C413.548 85.0352 418.284 87.4277 423.883 87.4277C428.277 87.4277 431.695 86.1908 434.137 83.7168C436.611 81.2428 437.848 77.7598 437.848 73.2676V68.7266C434.234 72.8932 429.303 74.9766 423.053 74.9766C416.868 74.9766 411.855 72.4863 408.014 67.5059C404.205 62.5254 402.301 55.7383 402.301 47.1445ZM411.383 48.1699C411.383 54.127 412.604 58.8145 415.045 62.2324C417.486 65.6178 420.904 67.3105 425.299 67.3105C430.995 67.3105 435.178 64.7227 437.848 59.5469V35.4258C435.081 30.3802 430.93 27.8574 425.396 27.8574C421.002 27.8574 417.568 29.5664 415.094 32.9844C412.62 36.4023 411.383 41.4642 411.383 48.1699Z"/>
</mask>
<path d="M42.4375 41.1387H11.627V66.334H47.418V74H2.25195V2.90625H46.9297V10.6211H11.627V33.4727H42.4375V41.1387Z" fill="#1A1717"/>
<path d="M73.834 61.7441L86.9199 21.168H96.1484L77.2031 74H70.3184L51.1777 21.168H60.4062L73.834 61.7441Z" fill="#1A1717"/>
<path d="M136.676 74C136.155 72.9583 135.732 71.1029 135.406 68.4336C131.207 72.7956 126.194 74.9766 120.367 74.9766C115.159 74.9766 110.878 73.5117 107.525 70.582C104.205 67.6198 102.545 63.8763 102.545 59.3516C102.545 53.8503 104.628 49.5859 108.795 46.5586C112.994 43.4987 118.886 41.9688 126.471 41.9688H135.26V37.8184C135.26 34.6608 134.316 32.1543 132.428 30.2988C130.54 28.4108 127.757 27.4668 124.078 27.4668C120.855 27.4668 118.154 28.2806 115.973 29.9082C113.792 31.5358 112.701 33.5052 112.701 35.8164H103.619C103.619 33.1797 104.547 30.6406 106.402 28.1992C108.29 25.7253 110.829 23.7721 114.02 22.3398C117.242 20.9076 120.774 20.1914 124.615 20.1914C130.702 20.1914 135.471 21.7214 138.922 24.7812C142.372 27.8086 144.163 31.9915 144.293 37.3301V61.6465C144.293 66.4967 144.911 70.3542 146.148 73.2188V74H136.676ZM121.686 67.1152C124.518 67.1152 127.203 66.3828 129.742 64.918C132.281 63.4531 134.12 61.5488 135.26 59.2051V48.3652H128.18C117.112 48.3652 111.578 51.6042 111.578 58.082C111.578 60.9141 112.522 63.1276 114.41 64.7227C116.298 66.3177 118.723 67.1152 121.686 67.1152Z" fill="#1A1717"/>
<path d="M166.998 21.168L167.291 27.8086C171.327 22.7305 176.601 20.1914 183.111 20.1914C194.277 20.1914 199.908 26.4902 200.006 39.0879V74H190.973V39.0391C190.94 35.2305 190.061 32.4147 188.336 30.5918C186.643 28.7689 183.99 27.8574 180.377 27.8574C177.447 27.8574 174.876 28.6387 172.662 30.2012C170.449 31.7637 168.723 33.8145 167.486 36.3535V74H158.453V21.168H166.998Z" fill="#1A1717"/>
<path d="M261.529 38.5996L280.084 2.90625H290.729L266.217 47.4863V74H256.842V47.4863L232.33 2.90625H243.072L261.529 38.5996Z" fill="#1A1717"/>
<path d="M327.496 74C326.975 72.9583 326.552 71.1029 326.227 68.4336C322.027 72.7956 317.014 74.9766 311.188 74.9766C305.979 74.9766 301.699 73.5117 298.346 70.582C295.025 67.6198 293.365 63.8763 293.365 59.3516C293.365 53.8503 295.449 49.5859 299.615 46.5586C303.814 43.4987 309.706 41.9688 317.291 41.9688H326.08V37.8184C326.08 34.6608 325.136 32.1543 323.248 30.2988C321.36 28.4108 318.577 27.4668 314.898 27.4668C311.676 27.4668 308.974 28.2806 306.793 29.9082C304.612 31.5358 303.521 33.5052 303.521 35.8164H294.439C294.439 33.1797 295.367 30.6406 297.223 28.1992C299.111 25.7253 301.65 23.7721 304.84 22.3398C308.062 20.9076 311.594 20.1914 315.436 20.1914C321.523 20.1914 326.292 21.7214 329.742 24.7812C333.193 27.8086 334.983 31.9915 335.113 37.3301V61.6465C335.113 66.4967 335.732 70.3542 336.969 73.2188V74H327.496ZM312.506 67.1152C315.338 67.1152 318.023 66.3828 320.562 64.918C323.102 63.4531 324.941 61.5488 326.08 59.2051V48.3652H319C307.932 48.3652 302.398 51.6042 302.398 58.082C302.398 60.9141 303.342 63.1276 305.23 64.7227C307.118 66.3177 309.544 67.1152 312.506 67.1152Z" fill="#1A1717"/>
<path d="M357.818 21.168L358.111 27.8086C362.148 22.7305 367.421 20.1914 373.932 20.1914C385.097 20.1914 390.729 26.4902 390.826 39.0879V74H381.793V39.0391C381.76 35.2305 380.882 32.4147 379.156 30.5918C377.464 28.7689 374.811 27.8574 371.197 27.8574C368.268 27.8574 365.696 28.6387 363.482 30.2012C361.269 31.7637 359.544 33.8145 358.307 36.3535V74H349.273V21.168H357.818Z" fill="#1A1717"/>
<path d="M402.301 47.1445C402.301 38.9089 404.205 32.3659 408.014 27.5156C411.822 22.6328 416.868 20.1914 423.15 20.1914C429.596 20.1914 434.625 22.4701 438.238 27.0273L438.678 21.168H446.93V72.7305C446.93 79.5664 444.895 84.9538 440.826 88.8926C436.79 92.8314 431.354 94.8008 424.518 94.8008C420.709 94.8008 416.982 93.987 413.336 92.3594C409.69 90.7318 406.907 88.502 404.986 85.6699L409.674 80.25C413.548 85.0352 418.284 87.4277 423.883 87.4277C428.277 87.4277 431.695 86.1908 434.137 83.7168C436.611 81.2428 437.848 77.7598 437.848 73.2676V68.7266C434.234 72.8932 429.303 74.9766 423.053 74.9766C416.868 74.9766 411.855 72.4863 408.014 67.5059C404.205 62.5254 402.301 55.7383 402.301 47.1445ZM411.383 48.1699C411.383 54.127 412.604 58.8145 415.045 62.2324C417.486 65.6178 420.904 67.3105 425.299 67.3105C430.995 67.3105 435.178 64.7227 437.848 59.5469V35.4258C435.081 30.3802 430.93 27.8574 425.396 27.8574C421.002 27.8574 417.568 29.5664 415.094 32.9844C412.62 36.4023 411.383 41.4642 411.383 48.1699Z" fill="#1A1717"/>
<path d="M42.4375 41.1387H11.627V66.334H47.418V74H2.25195V2.90625H46.9297V10.6211H11.627V33.4727H42.4375V41.1387Z" stroke="#E23C3C" stroke-width="4" mask="url(#path-1-outside-1_2_7)"/>
<path d="M73.834 61.7441L86.9199 21.168H96.1484L77.2031 74H70.3184L51.1777 21.168H60.4062L73.834 61.7441Z" stroke="#E23C3C" stroke-width="4" mask="url(#path-1-outside-1_2_7)"/>
<path d="M136.676 74C136.155 72.9583 135.732 71.1029 135.406 68.4336C131.207 72.7956 126.194 74.9766 120.367 74.9766C115.159 74.9766 110.878 73.5117 107.525 70.582C104.205 67.6198 102.545 63.8763 102.545 59.3516C102.545 53.8503 104.628 49.5859 108.795 46.5586C112.994 43.4987 118.886 41.9688 126.471 41.9688H135.26V37.8184C135.26 34.6608 134.316 32.1543 132.428 30.2988C130.54 28.4108 127.757 27.4668 124.078 27.4668C120.855 27.4668 118.154 28.2806 115.973 29.9082C113.792 31.5358 112.701 33.5052 112.701 35.8164H103.619C103.619 33.1797 104.547 30.6406 106.402 28.1992C108.29 25.7253 110.829 23.7721 114.02 22.3398C117.242 20.9076 120.774 20.1914 124.615 20.1914C130.702 20.1914 135.471 21.7214 138.922 24.7812C142.372 27.8086 144.163 31.9915 144.293 37.3301V61.6465C144.293 66.4967 144.911 70.3542 146.148 73.2188V74H136.676ZM121.686 67.1152C124.518 67.1152 127.203 66.3828 129.742 64.918C132.281 63.4531 134.12 61.5488 135.26 59.2051V48.3652H128.18C117.112 48.3652 111.578 51.6042 111.578 58.082C111.578 60.9141 112.522 63.1276 114.41 64.7227C116.298 66.3177 118.723 67.1152 121.686 67.1152Z" stroke="#E23C3C" stroke-width="4" mask="url(#path-1-outside-1_2_7)"/>
<path d="M166.998 21.168L167.291 27.8086C171.327 22.7305 176.601 20.1914 183.111 20.1914C194.277 20.1914 199.908 26.4902 200.006 39.0879V74H190.973V39.0391C190.94 35.2305 190.061 32.4147 188.336 30.5918C186.643 28.7689 183.99 27.8574 180.377 27.8574C177.447 27.8574 174.876 28.6387 172.662 30.2012C170.449 31.7637 168.723 33.8145 167.486 36.3535V74H158.453V21.168H166.998Z" stroke="#E23C3C" stroke-width="4" mask="url(#path-1-outside-1_2_7)"/>
<path d="M261.529 38.5996L280.084 2.90625H290.729L266.217 47.4863V74H256.842V47.4863L232.33 2.90625H243.072L261.529 38.5996Z" stroke="#E23C3C" stroke-width="4" mask="url(#path-1-outside-1_2_7)"/>
<path d="M327.496 74C326.975 72.9583 326.552 71.1029 326.227 68.4336C322.027 72.7956 317.014 74.9766 311.188 74.9766C305.979 74.9766 301.699 73.5117 298.346 70.582C295.025 67.6198 293.365 63.8763 293.365 59.3516C293.365 53.8503 295.449 49.5859 299.615 46.5586C303.814 43.4987 309.706 41.9688 317.291 41.9688H326.08V37.8184C326.08 34.6608 325.136 32.1543 323.248 30.2988C321.36 28.4108 318.577 27.4668 314.898 27.4668C311.676 27.4668 308.974 28.2806 306.793 29.9082C304.612 31.5358 303.521 33.5052 303.521 35.8164H294.439C294.439 33.1797 295.367 30.6406 297.223 28.1992C299.111 25.7253 301.65 23.7721 304.84 22.3398C308.062 20.9076 311.594 20.1914 315.436 20.1914C321.523 20.1914 326.292 21.7214 329.742 24.7812C333.193 27.8086 334.983 31.9915 335.113 37.3301V61.6465C335.113 66.4967 335.732 70.3542 336.969 73.2188V74H327.496ZM312.506 67.1152C315.338 67.1152 318.023 66.3828 320.562 64.918C323.102 63.4531 324.941 61.5488 326.08 59.2051V48.3652H319C307.932 48.3652 302.398 51.6042 302.398 58.082C302.398 60.9141 303.342 63.1276 305.23 64.7227C307.118 66.3177 309.544 67.1152 312.506 67.1152Z" stroke="#E23C3C" stroke-width="4" mask="url(#path-1-outside-1_2_7)"/>
<path d="M357.818 21.168L358.111 27.8086C362.148 22.7305 367.421 20.1914 373.932 20.1914C385.097 20.1914 390.729 26.4902 390.826 39.0879V74H381.793V39.0391C381.76 35.2305 380.882 32.4147 379.156 30.5918C377.464 28.7689 374.811 27.8574 371.197 27.8574C368.268 27.8574 365.696 28.6387 363.482 30.2012C361.269 31.7637 359.544 33.8145 358.307 36.3535V74H349.273V21.168H357.818Z" stroke="#E23C3C" stroke-width="4" mask="url(#path-1-outside-1_2_7)"/>
<path d="M402.301 47.1445C402.301 38.9089 404.205 32.3659 408.014 27.5156C411.822 22.6328 416.868 20.1914 423.15 20.1914C429.596 20.1914 434.625 22.4701 438.238 27.0273L438.678 21.168H446.93V72.7305C446.93 79.5664 444.895 84.9538 440.826 88.8926C436.79 92.8314 431.354 94.8008 424.518 94.8008C420.709 94.8008 416.982 93.987 413.336 92.3594C409.69 90.7318 406.907 88.502 404.986 85.6699L409.674 80.25C413.548 85.0352 418.284 87.4277 423.883 87.4277C428.277 87.4277 431.695 86.1908 434.137 83.7168C436.611 81.2428 437.848 77.7598 437.848 73.2676V68.7266C434.234 72.8932 429.303 74.9766 423.053 74.9766C416.868 74.9766 411.855 72.4863 408.014 67.5059C404.205 62.5254 402.301 55.7383 402.301 47.1445ZM411.383 48.1699C411.383 54.127 412.604 58.8145 415.045 62.2324C417.486 65.6178 420.904 67.3105 425.299 67.3105C430.995 67.3105 435.178 64.7227 437.848 59.5469V35.4258C435.081 30.3802 430.93 27.8574 425.396 27.8574C421.002 27.8574 417.568 29.5664 415.094 32.9844C412.62 36.4023 411.383 41.4642 411.383 48.1699Z" stroke="#E23C3C" stroke-width="4" mask="url(#path-1-outside-1_2_7)"/>
</svg>

      </div >
      
          <h3 class="school">Stevens Institute of Technology</h3>
          <h4 class="title">Computer Science</h4>
      </div>

      <div class="bounce">
        <i class="fas fa-chevron-down"></i>
      </div>
      
      <div class="second-container">
      </div>

  </section>
</template>

<script>
import anime from "animejs";

export default {
  name: 'Intro',
  props: {
  },
  mounted: function () {
    this.animation();
  },
  methods: {
    animation() {
      anime({
  targets: '#amazing path',
  strokeDashoffset: [anime.setDashoffset, 0],
  easing: 'easeInOutSine',
  duration: 1500,
  delay: 300,
  direction: 'alternate',
  loop: true
});
    }
  }
}

</script>

<style>
.intro-section {
  height: 100vh;
  background-color: var(--main);
  display: flex;

}

.first-container {
  width: 50%;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 text-align: center;
}

.second-container {
  width: 50%;
  background-image: url("../assets/design2.jpg");
  background-size: cover;
  margin-top: 2rem;
}

.svg-container {
  width: 60%;
}


.name {
  font-family: 'Lobster Two', cursive;
  font-size: 10rem;
  color: var(--text2);
  font-weight: 300;
  padding: 2rem;
}

.school {
  color: #C1BDBD;
  font-weight: 500;
  padding: 0.5rem;
}

.title {
  color: var(--text2);
  font-weight: 400;
  font-size: 2.5rem;
}

.fa-chevron-down {
  font-size: 5rem;
  color: var(--text2);
}

.bounce {
	position: absolute;
	bottom: 3rem;
	left: 50%;
	animation: bounce 2s infinite;
	-webkit-animation: bounce 2s infinite;
	-moz-animation: bounce 2s infinite;
	-o-animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}	
	40% {-webkit-transform: translateY(-30px);}
	60% {-webkit-transform: translateY(-15px);}
}
 
@-moz-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
	40% {-moz-transform: translateY(-30px);}
	60% {-moz-transform: translateY(-15px);}
}
 
@-o-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
	40% {-o-transform: translateY(-30px);}
	60% {-o-transform: translateY(-15px);}
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}

.fa-chevron-down:hover {
  animation-name: bounce;
  -moz-animation-name: bounce;
}




</style>
